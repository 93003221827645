import { css } from '@emotion/react'
import { StaticImage } from 'gatsby-plugin-image'
import React, { ReactNode } from 'react'

import { mq, rem } from '../../styles/mixin.styles'
import { breakpoints, colors, space } from '../../styles/variables.styles'
import { generatePath } from '../../utils/customPath'
import { ButtonLink } from '../common/ButtonLink'

type Props = {
  children?: ReactNode
  type?: 'other' | 'top'
}

/**
 * Aside
 *
 * @param children children
 * @returns global Aside
 */
export const Aside = ({ children, type = 'other' }: Props) => {
  return (
    <aside css={[asideStyles, type === 'top' && topStyles]}>
      <div>
        <article className="card">
          <div className="card__top">
            <h2 className="card__title">
              iPaSの導入に関して
              <br className="top-mobile-only" />
              ご不明な
              <br className="mobile-only" />
              点など<span className="desktop-only">、</span>
              <br className="desktop-only" />
              ぜひご相談ください
            </h2>
            {type === 'top' && (
              <div className="card__image">
                <StaticImage
                  src="../../images/aside-image.png"
                  width={250}
                  height={218}
                  placeholder="none"
                  alt="ご相談窓口のイメージ: パソコンの前にヘッドセットを装着して座って会話している様子"
                />
              </div>
            )}
          </div>

          <p className="card__read">
            iPaSはパレット調達業務を大幅に効率化
            <br className="mobile-only" />
            するためのツールです。
            <br />
            鉄鋼流通商社として長年にわたり自動車や家電製品
            <br className="mobile-only" />
            向けの素材である薄鋼板を供給してきた
            <br />
            五十鈴グループのノウハウを活かし、
            <br className="mobile-only" />
            パレット調達業務を一気通貫でサポートします。
          </p>

          <ButtonLink link={generatePath('contact')} style={`primary`}>
            導入のご相談はこちら
          </ButtonLink>
          <div className="card__option">{children}</div>
        </article>
      </div>
      {children}
    </aside>
  )
}

const asideStyles = css`
  width: 100%;
  background-color: ${colors.reMainBlue.hex};
  .card {
    width: min(calc(100% - ${space.sm} * 2), ${rem(breakpoints.content)});
    margin: 0 auto;
    text-align: center;
    padding-top: ${rem(50)};
    padding-bottom: ${rem(50)};
    .card__title {
      font-size: clamp(${rem(24)}, 5vw, ${rem(32)});
      line-height: clamp(${rem(38)}, 7vw, ${rem(46)});
      color: ${colors.white.hex};
      letter-spacing: 0.02em;
      font-family: var(--roman-font-family);
    }
    .card__read {
      font-size: clamp(${rem(14)}, 3vw, ${rem(16)});
      line-height: clamp(${rem(28)}, 3vw, ${rem(32)});
      color: ${colors.white.hex};
      margin-top: clamp(${rem(16)}, 4vw, ${rem(20)});
      margin-bottom: clamp(${rem(34)}, 4vw, ${rem(30)});
    }
  }
  .top-mobile-only {
    display: none;
  }
  .top-desktop-only {
    display: none;
  }
  .desktop-only {
    display: none;
  }
  .mobile-only {
    display: inline-block;
  }
  ${mq('lg')} {
    .card {
    }
    .desktop-only {
      display: inline-block;
    }
    .mobile-only {
      display: none;
    }
  }
`

const topStyles = css`
  .card {
    position: relative;
    .card__top {
      display: flex;
    }
    .card__title {
      font-size: calc(${rem(24)}, 5vw, ${rem(32)});
      line-height: calc(${rem(35)}, 5vw, ${rem(46)});
      text-align: left;
      letter-spacing: 0.02em;
    }
    .card__read {
      border-top: ${rem(1)} solid ${colors.white.hex};
      padding-top: ${space.md};
      text-align: left;
      position: relative;
      &::before {
        content: '';
        width: 30vw;
        height: ${rem(1)};
        position: absolute;
        top: -${rem(1)};
        right: -30vw;
        background-color: ${colors.white.hex};
      }
    }
    .card__image {
      max-width: 32vw;
      margin-right: -${rem(10)};
    }
  }
  .top-mobile-only {
    display: block;
  }
  .mobile-only {
    display: none;
  }
  .desktop-only {
    display: inline-block;
  }
  ${mq('md')} {
    .card {
      padding: ${rem(80)} 0;
      .card__title {
        text-align: left;
        letter-spacing: 0.02em;
      }
      .card__image {
        position: absolute;
        right: -1rem;
        top: 2rem;
        bottom: 0;
        margin: auto;
        max-width: 100%;
        z-index: 1;
        margin-right: 0;
        max-width: ${rem(192)};
      }
    }
  }
  ${mq('lg')} {
    .card {
      .card__read {
        &::before {
          display: none;
        }
      }
      .card__image {
        top: 3rem;
        max-width: 100%;
      }
    }
    .top-mobile-only {
      display: none;
    }
  }
`
